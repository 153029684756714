<template>
    <div class="">
        <Modal  :isLoadingSubmitBtn="isLoading" :segments="segments" @submit="saveSegment($event)" title="Create Segment" ref="createSegment"></Modal>
        
            </div>
</template>
<script>
import Modal from './Modal.vue';
import axios from 'axios';
export default {
    props:{
        loadSegments:Function
    },
components:{
    Modal
},
mounted(){
    this.windowOpen = this.$route.query.redirect||false
},
data(){
    return {
        windowOpen:false,
        isLoading:false,
        segments:{
      name: "",
      quiz_id: "",
    
      "stanzas": [
        {
            "criteria": [
               
                {
                    "question_id": '',
                    "option_id": ''
                }
            ]
        },
       
    ]
    }
    }
},
methods:{
    performAction() {
      window.opener.postMessage('close', '*');
      window.close();
    },
    async saveSegment(data){
        this.isLoading=true;
        let payload = {...data}
        
        await axios.post('/api/v1/segments',payload
        ).then(()=>{
            if(this.windowOpen)
          {
            this.performAction()
          }
            this.$router.push({name:'SegmentsLeads'})
              this.$toasted.show("Segment created successfully", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        }).catch(()=>{
              this.$toasted.show("Error While Creating Segment", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        })
       this.isLoading=false;
    }
}
}
</script>
